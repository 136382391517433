import React, { useState } from "react";
import {
  Title,
  Button,
  Box,
  Input,
  Flex,
  Card,
  LoadingOverlay,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";

import logo from "../assets/images/logo.png";

interface ResponseType {
  status: string;
  message: string;
  data: {
    access_token: string;
    refresh_token: string;
    username: string;
    id: string;
  };
}

const Sign: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = () => {
    if (!username || !password) {
      notifications.show({
        message: "請輸入帳號與密碼",
      });
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        res.json().then((data: ResponseType) => {
          setLoading(false);
          notifications.show({
            message: data.message,
          });
          if (data.status !== "error") {
            localStorage.setItem("token", data.data.access_token);
            localStorage.setItem("refresh_token", data.data.refresh_token);
            window.location.href = "/employee";
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        notifications.show({
          message: error,
        });
      });
  };

  return (
    <Box pos="relative">
      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Flex
        direction="column"
        justify={"center"}
        align={"center"}
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}>
        <Card
          shadow="sm"
          padding="lg"
          radius="md"
          withBorder
          style={{ width: 400 }}>
          <Card.Section
            style={{
              backgroundColor: "#CFEEFF",
              padding: "20px",
            }}>
            <Flex justify={"center"} align={"center"}>
              <img src={logo} alt="logo" style={{ width: 150 }} />
            </Flex>
          </Card.Section>
          <Title order={1}>登入</Title>
          <Input.Wrapper label="帳號">
            <Input
              placeholder="請輸入帳號"
              value={username}
              onChange={(e) => setUsername(e.currentTarget.value)}
            />
          </Input.Wrapper>
          <Input.Wrapper label="密碼">
            <Input
              placeholder="請輸入密碼"
              type="password"
              style={{ marginTop: 10 }}
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
          </Input.Wrapper>
          <Flex>
            <Button
              color="blue"
              onClick={handleLogin}
              style={{ marginTop: 20 }}
              fullWidth>
              登入
            </Button>
          </Flex>
        </Card>
      </Flex>
    </Box>
  );
};

export default Sign;
