import { createTheme, MantineProvider } from "@mantine/core";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import ProtectRoutes from "./components/ProtectedRoute";
import Shell from "./components/Shell";
import Employee from "./pages/Employee";
import Files from "./pages/Files";
import SignIn from "./pages/SignIn";
import { Notifications } from "@mantine/notifications";

import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <SignIn />,
  },
  {
    path: "/employee",
    element: (
      <ProtectRoutes>
        <Shell>
          <Employee />
        </Shell>
      </ProtectRoutes>
    ),
  },
  {
    path: "/files",
    element: (
      <ProtectRoutes>
        <Shell>
          <Files />
        </Shell>
      </ProtectRoutes>
    ),
  },
  {
    path: "*",
    element: <Navigate to="/employee" replace />, // Redirect to home for any unknown route
  },
]);

const theme = createTheme({
  /** Put your mantine theme override here */
});

function App() {
  return (
    <MantineProvider theme={theme}>
      <Notifications />
      <RouterProvider router={router} />
    </MantineProvider>
  );
}

export default App;
