import { useState, useEffect } from "react";
import {
  Table,
  Title,
  Flex,
  FileButton,
  Button,
  Chip,
  Box,
  LoadingOverlay,
  rem,
  Tooltip,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import {
  IconX,
  IconCircle,
  IconSettingsPause,
  IconCircleCheck,
} from "@tabler/icons-react";

interface FileDataType {
  id: string;
  name: string;
  status: "uploaded" | "parsing" | "done" | "error";
  update_user: string;
  create_at: Date;
  update_at: Date;
  download_link: string;
}

const Files: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState<File | null>(null);
  const [files, setFiles] = useState<FileDataType[] | []>([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/files`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      res.json().then((data) => {
        setFiles(data.data);
        setLoading(false);
      });
    });
  }, [file]);

  useEffect(() => {
    if (file) {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      fetch(`${process.env.REACT_APP_API_URL}/api/files/upload`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then((response) => {
        console.log(response);
        response.json().then((response) => {
          notifications.show({
            message: response.message,
          });
          setFile(null);
          setLoading(false);
        });
      });
    }
  }, [file]);

  return (
    <Box pos="relative">
      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
        style={{
          width: "calc(100vw - 330px)",
        }}
      />
      <Flex
        direction="column"
        style={{
          width: "calc(100vw - 330px)",
        }}>
        <Flex justify={"space-between"} align={"center"}>
          <Title order={1}>檔案管理</Title>
          <FileButton onChange={setFile} accept="">
            {(props) => <Button {...props}>上傳檔案</Button>}
          </FileButton>
        </Flex>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>檔案名稱</Table.Th>
              <Table.Th>檔案狀態</Table.Th>
              <Table.Th>上傳者</Table.Th>
              <Table.Th>更新者</Table.Th>
              <Table.Th>更新時間</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {files.map((file: any) => (
              <Table.Tr key={file.id}>
                {file.status === "done" && (
                  <Table.Td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/api/excel/${file.download_link}`}
                      download
                      target="_blank"
                      rel="noreferrer">
                      {file.name}
                    </a>
                  </Table.Td>
                )}
                {file.status !== "done" && <Table.Td> {file.name}</Table.Td>}
                <Table.Td>
                  {file.status === "uploaded" && (
                    <Chip
                      defaultChecked
                      size="xs"
                      icon={
                        <IconCircle
                          style={{ width: rem(16), height: rem(16) }}
                        />
                      }
                      variant="light">
                      已上傳
                    </Chip>
                  )}
                  {file.status === "parsing" && (
                    <Chip
                      defaultChecked
                      size="xs"
                      icon={
                        <IconSettingsPause
                          style={{ width: rem(16), height: rem(16) }}
                        />
                      }
                      variant="light"
                      color="yellow">
                      轉檔中
                    </Chip>
                  )}
                  {file.status === "done" && (
                    <Chip
                      defaultChecked
                      size="xs"
                      icon={
                        <IconCircleCheck
                          style={{ width: rem(16), height: rem(16) }}
                        />
                      }
                      variant="light"
                      color="lime">
                      轉檔成功
                    </Chip>
                  )}
                  {file.status === "error" && (
                    <Chip
                      defaultChecked
                      size="xs"
                      icon={
                        <IconX style={{ width: rem(16), height: rem(16) }} />
                      }
                      variant="light"
                      color="red">
                      <Tooltip label={file.error}>
                        <p>發生錯誤</p>
                      </Tooltip>
                    </Chip>
                  )}
                </Table.Td>
                <Table.Td>{file.create_user}</Table.Td>
                <Table.Td>{file.update_user}</Table.Td>
                <Table.Td>
                  {new Date(file.update_at).toLocaleDateString()}{" "}
                  {new Date(file.update_at).toLocaleTimeString()}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Flex>
    </Box>
  );
};

export default Files;
