import {
  AppShell,
  Burger,
  Group,
  List,
  Text,
  ThemeIcon,
  rem,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { NavLink, useLocation } from "react-router-dom";
import { IconUser, IconFileExcel } from "@tabler/icons-react";

import logo from "../assets/images/logo.png";

type Props = {
  children: JSX.Element;
};

const Shell: React.FC<Props> = ({ children }: Props) => {
  const location = useLocation();
  const [opened, { toggle }] = useDisclosure();

  return (
    <AppShell
      header={{ height: 60 }}
      footer={{ height: 60 }}
      navbar={{ width: 300, breakpoint: "sm", collapsed: { mobile: !opened } }}
      aside={{
        width: 300,
        breakpoint: "md",
        collapsed: { desktop: false, mobile: true },
      }}
      padding="md">
      <AppShell.Header>
        <Group h="100%" px="md">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <img src={logo} alt="logo" style={{ width: 50 }} />
          <Text size="xl" fw={700}>
            百麒洗衣實業有限公司
          </Text>
        </Group>
      </AppShell.Header>
      <AppShell.Navbar
        p="md"
        style={{
          height: "calc(100vh - 59px)",
        }}>
        <List spacing="md">
          <List.Item
            style={{
              backgroundColor:
                location.pathname === "/employee"
                  ? "rgba(0, 0, 0, 0.1)"
                  : "transparent",
              padding: "10px",
              borderRadius: "10px",
            }}
            icon={
              <ThemeIcon color="blue" size={24} radius="xl">
                <IconUser style={{ width: rem(16), height: rem(16) }} />
              </ThemeIcon>
            }>
            <NavLink
              to="/employee"
              style={{
                textDecoration: "none",
                color: "inherit",
                backgroundColor: "red",
              }}>
              <Text>員工資料</Text>
            </NavLink>
          </List.Item>
          <List.Item
            style={{
              backgroundColor:
                location.pathname === "/files"
                  ? "rgba(0, 0, 0, 0.1)"
                  : "transparent",
              padding: "10px",
              borderRadius: "10px",
            }}
            icon={
              <ThemeIcon color="blue" size={24} radius="xl">
                <IconFileExcel style={{ width: rem(16), height: rem(16) }} />
              </ThemeIcon>
            }>
            <NavLink
              to="/files"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}>
              <Text>檔案管理</Text>
            </NavLink>
          </List.Item>
        </List>
      </AppShell.Navbar>
      <AppShell.Main
        style={{
          overflowY: "scroll",
          height: "calc(100vh - 59px)",
        }}>
        {children}
      </AppShell.Main>
    </AppShell>
  );
};

export default Shell;
