import React, { useState, useEffect } from "react";
import {
  Table,
  Title,
  Button,
  Modal,
  Input,
  Flex,
  Select,
  LoadingOverlay,
  Box,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
interface EmployeeDataType {
  id?: string;
  employee_no?: string;
  name?: string;
  department: string;
  employee_type: "full_time" | "part_time";
  base_salary: number;
  create_at?: Date;
  update_at?: Date;
  from_file_id?: string;
  create_user?: string;
  update_user?: string;
}

interface EditEmployeeProps {
  data: EmployeeDataType | undefined;
}

const Employee: React.FC = () => {
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<string>("");
  const [employeeData, setEmployeeData] = useState<EmployeeDataType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getEmployeeData = async () => {
      setLoading(true);
      fetch(`${process.env.REACT_APP_API_URL}/api/employee`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then((response) => {
        response.json().then((data) => {
          setEmployeeData(data.data || []);
          setLoading(false);
        });
      });
    };
    if (!openEditModal && !openAddModal) {
      getEmployeeData();
    }
  }, [openEditModal, openAddModal]);

  return (
    <Box
      pos="relative"
      style={{
        width: "calc(100vw - 330px)",
      }}>
      <LoadingOverlay visible={loading} />
      <Flex
        direction="column"
        style={{
          width: "calc(100vw - 330px)",
        }}>
        <Flex justify={"space-between"} align={"center"}>
          <Title>員工資料</Title>
          <Button
            size="xs"
            onClick={() => {
              setOpenAddModal(true);
            }}>
            新增員工
          </Button>
        </Flex>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>員工編號</Table.Th>
              <Table.Th>員工姓名</Table.Th>
              <Table.Th>部門</Table.Th>
              <Table.Th>類型</Table.Th>
              <Table.Th>底薪</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {employeeData.map((employee) => (
              <Table.Tr key={employee.id}>
                <Table.Td>{employee.employee_no}</Table.Td>
                <Table.Td>{employee.name}</Table.Td>
                <Table.Td>{employee.department}</Table.Td>
                <Table.Td>
                  {employee.employee_type === "full_time" ? "正職" : "兼職"}
                </Table.Td>
                <Table.Td>{employee.base_salary}</Table.Td>
                <Table.Td>
                  <Button
                    color="blue"
                    size="xs"
                    onClick={() => {
                      if (!employee.name) return;
                      setSelectedEmployee(employee.name);
                      setOpenEditModal(true);
                    }}>
                    編輯
                  </Button>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Flex>
      <Modal
        opened={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
        title={`編輯${selectedEmployee}資料`}
        centered>
        <EditEmployee
          data={employeeData.find(
            (employee) => employee.name === selectedEmployee
          )}
        />
      </Modal>
      <Modal
        opened={openAddModal}
        onClose={() => {
          setOpenAddModal(false);
        }}
        title={`新增員工資料`}
        centered>
        <AddEmployee />
      </Modal>
    </Box>
  );
};

const EditEmployee: React.FC<EditEmployeeProps> = ({ data }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [employee, setEmployeeData] = useState<EmployeeDataType | undefined>({
    id: data?.id || "",
    department: data?.department || "",
    employee_type: data?.employee_type || "full_time",
    base_salary: data?.base_salary || 0,
  });

  const updateEmployee = async () => {
    if (!employee) return;
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/employee/${employee.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(employee),
    }).then((response) => {
      response.json().then((data: any) => {
        notifications.show({
          message: data.message,
        });
        setLoading(false);
      });
    });
  };

  return (
    <Box pos="relative">
      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Input.Wrapper label="部門">
        <Input
          value={employee?.department}
          onChange={(event) => {
            const value = event.target.value;
            const updatedEmployee = {
              id: data?.id || "",
              employee_type: data?.employee_type || "full_time",
              base_salary: data?.base_salary || 0,
              department: value,
            };
            setEmployeeData(updatedEmployee);
          }}
        />
      </Input.Wrapper>
      <Input.Wrapper label="類型">
        <Select
          value={employee?.employee_type}
          data={[
            {
              value: "full_time",
              label: "正職",
            },
            {
              value: "part_time",
              label: "兼職",
            },
          ]}
          onChange={(value) => {
            const updatedEmployee = {
              id: data?.id || "",
              employee_type: value as "full_time" | "part_time",
              department: data?.department || "",
              base_salary: data?.base_salary || 0,
            };
            setEmployeeData(updatedEmployee);
          }}
        />
      </Input.Wrapper>
      <Input.Wrapper label="底薪">
        <Input
          value={employee?.base_salary}
          onChange={(event) => {
            const value = event.target.value;
            const updatedEmployee = {
              id: data?.id || "",
              employee_type: data?.employee_type || "full_time",
              department: data?.department || "",
              base_salary: +value.replace(/[^\d.-]/g, ""),
            };
            setEmployeeData(updatedEmployee);
          }}
        />
      </Input.Wrapper>
      <Flex
        style={{
          padding: "10px 0",
        }}>
        <Button color="blue" size="xs" onClick={updateEmployee}>
          儲存
        </Button>
      </Flex>
    </Box>
  );
};

const AddEmployee: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [employee, setEmployeeData] = useState<EmployeeDataType>({
    name: "",
    employee_no: "",
    department: "",
    employee_type: "full_time",
    base_salary: 0,
  });

  const addEmployee = async () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/employee/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        ...employee,
        from_file_id: "",
      }),
    }).then((response: any) => {
      setLoading(false);
      setEmployeeData({
        name: "",
        employee_no: "",
        department: "",
        employee_type: "full_time",
        base_salary: 0,
      });
      response.json().then((data: any) => {
        notifications.show({
          message: data.message,
        });
      });
    });
  };

  return (
    <Box pos="relative">
      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Input.Wrapper label="編號">
        <Input
          value={employee?.employee_no}
          onChange={(event) => {
            setEmployeeData((employee) => ({
              ...employee,
              employee_no: event.target.value,
            }));
          }}
        />
      </Input.Wrapper>
      <Input.Wrapper label="姓名">
        <Input
          value={employee?.name}
          onChange={(event) => {
            setEmployeeData((employee) => ({
              ...employee,
              name: event.target.value,
            }));
          }}
        />
      </Input.Wrapper>
      <Input.Wrapper label="部門">
        <Input
          value={employee?.department}
          onChange={(event) => {
            setEmployeeData((employee) => ({
              ...employee,
              department: event.target.value,
            }));
          }}
        />
      </Input.Wrapper>
      <Input.Wrapper label="類型">
        <Select
          value={employee?.employee_type}
          data={[
            {
              value: "full_time",
              label: "正職",
            },
            {
              value: "part_time",
              label: "兼職",
            },
          ]}
          onChange={(value) => {
            if (value === "full_time" || value === "part_time") {
              setEmployeeData((employee) => ({
                ...employee,
                employee_type: value || "full_time",
              }));
            }
          }}
        />
      </Input.Wrapper>
      <Input.Wrapper label="底薪">
        <Input
          value={employee?.base_salary}
          onChange={(event) => {
            const value = event.target.value;
            setEmployeeData((employee) => ({
              ...employee,
              base_salary: +value.replace(/[^\d.-]/g, ""),
            }));
          }}
        />
      </Input.Wrapper>
      <Flex
        style={{
          padding: "10px 0",
        }}>
        <Button color="blue" size="xs" onClick={addEmployee}>
          儲存
        </Button>
      </Flex>
    </Box>
  );
};

export default Employee;
