import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

type Props = {
  children: JSX.Element;
};

const ProtectRoutes: React.FC<Props> = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login", { state: { from: location } });
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/verify`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (data.status === "error") {
          navigate("/login", { state: { from: location } });
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
        }
      } catch (error) {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        navigate("/login", { state: { from: location } });
      }
    };

    verifyToken();
  }, [navigate, location]);

  return <>{children}</>;
};

export default ProtectRoutes;
